
export class Config {
  title: string = 'Nezaboodka Blog';
  description: string = 'Official Blog of Nezaboodka Software';
  siteIcon: string = 'img/star-768x768.png';
  coverImage: string = 'img/nezaboodka.png';
  logo: string = 'img/star-768x768.png';
  siteUrl: string = 'https://blog.nezaboodka.com';
  facebook?: string = 'https://www.facebook.com/nezaboodka';
  twitter?: string = 'https://twitter.com/forgetdatanot';
  showSubscribe: boolean = false;
  mailchimpAction?: string = undefined;
  mailchimpName?: string = undefined;
};

export const config = new Config();

export default config;
