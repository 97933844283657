import { css } from '@emotion/css';

export const body = css`
  display: grid;
  grid-gap: 2em 4em;
  grid-template-columns: auto 31fr 31fr 38fr auto;
  grid-template-rows: auto auto 1fr auto auto;
  margin: 0;
  font-family: Calibri, Arial, Tahoma;
  font-size: calc(16px + (24 - 16) * (100vw - 640px) / (1920 - 640));
  overflow: hidden;
  min-height: 90vh;

  @media only screen and (orientation: portrait) { /* tablet */
    grid-gap: 1em 0.5em;
    grid-template-columns: auto 31fr 31fr 38fr auto;
    grid-template-rows: auto auto auto auto auto;
  }

  @media only screen and (max-width: 760px) { /* phone */
    grid-gap: 1em 0.5em;
    grid-template-columns: auto 31fr 31fr 38fr auto;
    grid-template-rows: auto auto auto auto auto;
  }
`;

export const topline = css`
  grid-row: 1;
  grid-column: 1 / span 5;
  background-color: rgba(0, 0, 0, 0.1);
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
`;

export const logo = css`
  z-index: 1000;
  grid-row: 1;
  grid-column: 2 / span 3;
  align-self: baseline;
  padding: 0.25em 0;
  font-size: 160%;

  img, svg {
    filter: drop-shadow(0 0 1px black);
    height: 1em;
  }

  @media only screen and (orientation: portrait) { /* tablet */
    font-size: 125%;
  }

  @media only screen and (max-width: 760px) { /* phone */
    font-size: 125%;
  }
`;

export const site = css`
  margin-left: 0.5ch;
  padding: 0 0.5ch;
  font-weight: bold;
  background-color: #00B3FF;
  color: white;
  text-shadow: 0 0 1px black;
`;

export const menu = css`
  z-index: 1000;
  grid-row: 1;
  grid-column: 3 / span 2;
  text-align: right;
  align-self: baseline;
  padding: 0.25em 0;
  font-size: 135%;

  @media only screen and (orientation: portrait) { /* tablet */
    font-size: 125%;
  }

  @media only screen and (max-width: 760px) { /* phone */
    font-size: 125%;
  }
`;

export const menuItem = css`
  /* border-top: .1em solid #00B3FF; */
  margin: 0 .5em;
  /* transition: border-top .1s ease; */

  img, svg {
    height: 1em;
    vertical-align: top;
    margin-top: 0.1em;
  }

  :first-of-type {
    margin-left: 0;
  }

  :last-of-type {
    margin-right: 0;
  }
`;

export const menuItemCurrent = css`
  border-top: .1em solid #00B3FF;
`;

export const nzTitle = css`
  grid-row: 2;
  grid-column: 2 / span 2;
  font-size: 300%;
  line-height: 1.2;
  font-weight: bold;
`;

export const heading = css`
  padding-left: 2ch;
  border-left: .25ch solid #00B3FF;
  color: #E0E0E0;
  /* text-transform: uppercase; */
  line-height: 1.2;
`;

export const main = css`
  grid-row: 2 / span 2;
  grid-column: 2 / span 2;
  font-size: 125%;
  line-height: 1.2;
  text-shadow: none;

  @media only screen and (orientation: portrait) { /* tablet */
    grid-row: 2;
    grid-column: 2 / span 3;
    border-right: none;
  }

  @media only screen and (max-width: 760px) { /* phone */
    grid-row: 2;
    grid-column: 2 / span 3;
    border-right: none;
  }
`;

export const also = css`
  position: relative;
  grid-row: 2 / span 2;
  grid-column: 4;
  font-size: 125%;
  /* background-color: rgba(0, 0, 0, 0.1); */
  /* border: 1px solid rgba(0, 0, 0, 0.2); */

  @media only screen and (orientation: portrait) { /* tablet */
    grid-row: 4;
    grid-column: 2 / span 3;
    border-right: none;
  }

  @media only screen and (max-width: 760px) { /* phone */
    grid-row: 4;
    grid-column: 2 / span 3;
    border-right: none;
  }
`;

export const footer = css`
  grid-row: 4 / span 2;
  grid-column: 1 / span 5;
  padding: 1em 0;
  text-align: center;
  font-size: 75%;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, .6);
  text-shadow: none;

  @media only screen and (orientation: portrait) { /* tablet */
    grid-row: 5;
    border-right: none;
  }

  @media only screen and (max-width: 760px) { /* phone */
    grid-row: 5;
    border-right: none;
  }
`;

export const notfound = css`
  font-size: 200%;
  text-align: center;
  margin-top: 1em;
  grid-row: 2;
  grid-column: 1 / span 5;
`;

export const x404 = css`
  font-size: 1000%;
  text-align: center;
  grid-row: 3 / span 4;
  grid-column: 1 / span 5;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.2);
  text-shadow: none;
`;
